// IMPORTS
import React, { Component } from "react";
import { View, TouchableOpacity, Image, Text } from "react-native";
import { styles } from "../styles/appStyles";
import { screen, grid, colors } from "../libraries/variables";
import Sprite from "../assets/sprites/mine.png";
// COMPONENT
export default class Cell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      revealed: false,
      isMine: Math.random() < 0.2,
      neighbors: null,
    };
  }
  // RESET
  reset = () => {
    this.setState({
      revealed: false,
      isMine: Math.random() < 0.2,
      neighbors: null,
    });
  };
  // REVEAL
  reveal = () => {
    if (!this.state.revealed) {
      this.setState({
        revealed: true,
      });
    }
  };
  // HANDLE REVEAL
  handleReveal = (fromUser) => {
    if (this.state.revealed || (!fromUser && this.state.isMine)) {
      return;
    }
    if (fromUser) {
      this.props.addToCount();
    }
    this.setState({ revealed: true }, () => {
      if (this.state.isMine) {
        this.props.gameOver();
      } else {
        this.props.revealNeighbors(this.props.x, this.props.y);
      }
    });
  };
  // RENDER
  render() {
    const { revealed, isMine, neighbors } = this.state;
    return revealed ? (
      <View style={styles.cellRevealed}>
        {isMine ? (
          <Image
            style={styles.cellImage}
            source={Sprite}
            resizeMode="contain"
          />
        ) : (
          neighbors && (
            <Text
              style={[styles.cellText, { color: colors.numbers[neighbors] }]}
            >
              {neighbors}
            </Text>
          )
        )}
      </View>
    ) : (
      <TouchableOpacity
        style={styles.cell}
        onPress={() => {
          this.handleReveal(true);
        }}
      />
    );
  }
}
