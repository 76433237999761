// IMPORTS
import { Dimensions } from "react-native";
// SCREEN
const screen = {
  height: Dimensions.get("screen").height,
  width: Dimensions.get("screen").width,
};
// GRID
const grid = {
  length: screen.width / 30 < 10 ? Math.floor(screen.width / 30) : 10,
  cell: 30,
};
// COLORS
const colors = {
  gray: "#888888",
  lightGray: "#bdbdbd",
  darkGray: "#7d7d7d",
  black: "#000000",
  blackFade: "rgba(0,0,0,0.5)",
  white: "#ffffff",
  whiteFade: "rgba(255, 255, 255, 0.5)",
  numbers: {
    1: "blue",
    2: "green",
    3: "red",
    4: "purple",
    5: "maroon",
    6: "turquoise",
    7: "black",
    8: "gray",
  },
  phone: "#1d1d1d",
  phoneLense: "#1f3a61",
  phoneSpeaker: "#161616",
  phoneBar: "#505050",
};
// EXPORTS
export { screen, grid, colors };
