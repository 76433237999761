// IMPORTS
import { StyleSheet, useWindowDimensions } from "react-native";
import { colors } from "../libraries/variables";
// STYLES
const webStyles = () => {
  const { height, width } = useWindowDimensions();
  const moveDescription = width < 1150;
  const styles = StyleSheet.create({
    // WEB
    webContainer: {
      height: "100%",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    webBackground: {
      height: "100%",
      width: "100%",
      position: "absolute",
      top: 0,
    },
    // DESCRIPTION
    descriptionContainer: {
      height: "auto",
      width: "auto",
      position: "absolute",
      top: moveDescription ? 20 : 50,
      left: moveDescription ? 20 : 50,
      backgroundColor: colors.whiteFade,
      padding: 10,
      borderRadius: 15,
    },
    descriptionTitle: {
      color: colors.black,
      fontSize: moveDescription ? 40 : 50,
      textAlign: "center",
    },
    descriptionText: {
      color: colors.black,
      fontSize: moveDescription ? 20 : 30,
    },
    descriptionImage: {
      height: moveDescription ? 25 : 40,
      width: moveDescription ? 25 : 40,
      position: "absolute",
      marginLeft: 5,
    },
    // PHONE
    phoneContainer: {
      height: 870,
      width: 420,
      alignItems: "center",
      justifyContent: "center",
      borderColor: colors.phone,
      borderWidth: 20,
      borderRadius: 50,
      overflow: "hidden",
    },
    phoneNotch: {
      height: 25,
      width: 175,
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      top: 0,
      backgroundColor: colors.phone,
      borderBottomLeftRadius: 40,
      borderBottomRightRadius: 40,
      paddingBottom: 10,
      zIndex: 10,
    },
    phoneLense: {
      height: 10,
      width: 10,
      position: "absolute",
      left: 35,
      backgroundColor: colors.phoneLense,
      borderRadius: 5,
    },
    phoneSpeaker: {
      height: 5,
      width: 50,
      backgroundColor: colors.phoneBar,
      borderRadius: 20,
    },
    phoneBar: {
      height: 8,
      width: 175,
      position: "absolute",
      bottom: 15,
      backgroundColor: colors.phoneBar,
      borderRadius: 20,
      zIndex: 10,
    },
  });
  return { screen: { height, width }, styles };
};
// EXPORT
export { webStyles };
