// IMPORTS
import { StyleSheet } from "react-native";
import { screen, grid, colors } from "../libraries/variables";
// STYLES
const styles = StyleSheet.create({
  // APP
  appContainer: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  appTitle: {
    fontSize: 40,
    fontWeight: "700",
  },
  appBackground: {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    zIndex: -1
  },
  // BOARD
  boardContainer: {
    height: grid.length * grid.cell,
    width: grid.length * grid.cell,
    backgroundColor: colors.gray,
    flexDirection: "column",
  },
  boardRowContainer: {
    height: grid.cell,
    width: grid.length * grid.cell,
    flexDirection: "row",
  },
  // CELL
  cell: {
    height: grid.cell,
    width: grid.cell,
    backgroundColor: colors.lightGray,
    borderWidth: 3,
    borderTopColor: colors.white,
    borderLeftColor: colors.white,
    borderRightColor: colors.darkGray,
    borderBottomColor: colors.darkGray,
  },
  cellRevealed: {
    height: grid.cell,
    width: grid.cell,
    backgroundColor: colors.lightGray,
    borderWidth: 1,
    borderColor: colors.darkGray,
    alignItems: "center",
    justifyContent: "center",
  },
  cellImage: {
    height: grid.cell * 0.8,
    width: grid.cell * 0.8,
  },
  cellText: {
    fontSize: 20,
    fontWeight: "700",
  },
  // GAME OVER
  gameOverContainer: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.blackFade,
    position: "absolute",
  },
  gameOverTitle: {
    color: colors.white,
    fontSize: 30,
  },
  gameOverText: {
    color: colors.white,
    fontSize: 20,
  },
  // BUTTON
  buttonContainer: {
    backgroundColor: colors.black,
    borderRadius: 10,
    padding: 10,
  },
  buttonText: {
    color: colors.white,
    fontSize: 20,
  },
});
// EXPORT
export { styles };
