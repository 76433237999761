// IMPORTS
import React, { Component } from "react";
import {
  View,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Text,
  ImageBackground,
} from "react-native";
import { registerRootComponent } from "expo";
import { screen, grid } from "./libraries/variables";
import { styles } from "./styles/appStyles";
import Cell from "./components/Cell";
import Background from "./assets/backgrounds/mines.png";
// COMPONENT
export default class App extends Component {
  constructor(props) {
    super(props);
    this.count = 0;
    this.boardWidth = grid.length * grid.cell;
    this.grid = Array.apply(null, Array(grid.length)).map((el, idx) => {
      return Array.apply(null, Array(grid.length)).map((el, idx) => {
        return null;
      });
    });
    this.state = {
      gameOver: false,
    };
  }
  // GAME OVER
  gameOver = () => {
    this.setState({ gameOver: true });
    for (let i = 0; i < grid.length; i++) {
      for (let j = 0; j < grid.length; j++) {
        this.grid[i][j].reveal();
      }
    }
  };
  // REVEAL NEIGHBOURS
  revealNeighbors = (x, y) => {
    let neighbors = 0;
    for (let i = -1; i <= 1; i++) {
      for (let j = -1; j <= 1; j++) {
        if (
          x + i >= 0 &&
          x + i <= grid.length - 1 &&
          y + j >= 0 &&
          y + j <= grid.length - 1
        ) {
          if (this.grid[x + i][y + j].state.isMine) {
            neighbors++;
          }
        }
      }
    }
    if (neighbors) {
      this.grid[x][y].setState({
        neighbors: neighbors,
      });
    } else {
      for (let i = -1; i <= 1; i++) {
        for (let j = -1; j <= 1; j++) {
          if (
            (i != 0 || j != 0) &&
            x + i >= 0 &&
            x + i <= grid.length - 1 &&
            y + j >= 0 &&
            y + j <= grid.length - 1
          ) {
            this.grid[x + i][y + j].handleReveal(false);
          }
        }
      }
    }
  };
  // GENERATE BOARD
  generateBoard = () => {
    const rows = Array.apply(null, Array(grid.length)).map((_, rowIdx) => {
      const columns = Array.apply(null, Array(grid.length)).map((_, colIdx) => {
        return (
          <Cell
            key={colIdx}
            addToCount={() => (this.count += 1)}
            gameOver={this.gameOver}
            revealNeighbors={this.revealNeighbors}
            x={colIdx}
            y={rowIdx}
            ref={(ref) => {
              this.grid[colIdx][rowIdx] = ref;
            }}
          />
        );
      });
      return (
        <View key={rowIdx} style={styles.boardRowContainer}>
          {columns}
        </View>
      );
    });
    return rows;
  };
  // RENDER
  render() {
    return (
      <View style={styles.appContainer}>
        <ImageBackground style={styles.appBackground} source={Background} />
        {/* TITLE */}
        <Text style={styles.appTitle}>Mine Sweeper</Text>
        {/* BOARD */}
        <View style={styles.boardContainer}>
          {this.generateBoard()}
          {this.state.gameOver && (
            <TouchableWithoutFeedback
              style={styles.gameOverContainer}
              onPress={() => this.setState({ gameOver: false })}
            >
              <View style={styles.gameOverContainer}>
                <Text style={styles.gameOverTitle}>Game Over</Text>
                <Text style={styles.gameOverText}>
                  You survived {this.count} clicks.
                </Text>
              </View>
            </TouchableWithoutFeedback>
          )}
        </View>
        {/* BUTTON */}
        <TouchableOpacity
          style={styles.buttonContainer}
          onPress={() => {
            this.count = 0;
            this.setState({ gameOver: false });
            for (let i = 0; i < grid.length; i++) {
              for (let j = 0; j < grid.length; j++) {
                this.grid[i][j].reset();
              }
            }
          }}
        >
          <Text style={styles.buttonText}>New Game</Text>
        </TouchableOpacity>
      </View>
    );
  }
}
// INITIALIZE
registerRootComponent(App);
